import * as yup from 'yup';
import { Validations } from '../../config';

export const getInputSchema = (text) => {
  const schemaEntries = text.reduce((acc, item) => {
    acc[item.value] = Validations.customText;
    return acc;
  }, {});

  return yup.object().shape(schemaEntries);
};

export const generateValidationSchema = (fields) => {
  const schemaFields = {};
  const maxLengthMap = {};

  fields.forEach((field) => {
    switch (field.value) {
      case 'entity':
        schemaFields[field.value] = yup.string().required('PAN Number is required').matches(/^[A-Za-z0-9]{10}$/, 'PAN Number must be 10 alphanumeric digits');
        maxLengthMap[field.value] = 10;
        break;
      case 'pancard':
        schemaFields[field.value] = yup.string().required('PAN Number is required').matches(/^[A-Za-z0-9]{10}$/, 'PAN Number must be 10 alphanumeric digits');
        maxLengthMap[field.value] = 10;
        break;
      case 'pan':
          schemaFields[field.value] = yup.string().required('PAN Number is required').matches(/^[A-Za-z0-9]{10}$/, 'PAN Number must be 10 alphanumeric digits');
          maxLengthMap[field.value] = 10;
          break;
      case 'pan_number':
        schemaFields[field.value] = yup.string().required('PAN Number is required').matches(/^[A-Za-z0-9]{10}$/, 'PAN Number must be 10 alphanumeric digits');
        maxLengthMap[field.value] = 10;
        break;
      case 'panNumber':
        schemaFields[field.value] = yup.string().required('PAN Number is required').matches(/^[A-Za-z0-9]{10}$/, 'PAN Number must be 10 alphanumeric digits');
        maxLengthMap[field.value] = 10;
        break;
      case 'din_number':
        schemaFields[field.value] = yup.string().required('DIN Number is required').matches(/^[A-Za-z0-9]{10}$/, 'DIN Number must be 20 alphanumeric digits');
        maxLengthMap[field.value] = 20;
        break;
      case 'acc_number':
        schemaFields[field.value] = yup.string().required('Account number is required').matches(/^[A-Za-z0-9]{5,20}$/, 'Invalid bank account number format. Must be 5 to 20 alphanumeric digits.');
        maxLengthMap[field.value] = 20;
        break;
      case 'ifsc_number':
        schemaFields[field.value] = yup.string().required('IFSC code is required').matches(/^[A-Za-z0-9]{5,20}$/, 'Invalid IFSC code format. Must be 5 to 20 alphanumeric digits.');
        maxLengthMap[field.value] = 20;
        break;
      case 'ifsc_code':
        schemaFields[field.value] = yup.string().required('IFSC code is required').matches(/^[A-Za-z0-9]{5,20}$/, 'Invalid IFSC code format. Must be 5 to 20 alphanumeric digits.');
        maxLengthMap[field.value] = 20;
        break;
      case 'pincode1':
        schemaFields[field.value] = yup.string().required('Source Pincode is required').matches(/^[0-9]{5,6}$/, 'Invalid Source Pincode format. Must be 5 to 6 digits.');
        maxLengthMap[field.value] = 6;
        break;
      case 'pincode2':
        schemaFields[field.value] = yup.string().required('Destination Pincode is required').matches(/^[0-9]{5,6}$/, 'Invalid Destination Pincode format. Must be 5 to 8 digits.');
        maxLengthMap[field.value] = 6;
        break;
      case 'dl_number':
        schemaFields[field.value] = yup.string().required('Driving License Number is required').matches(/^[A-Za-z0-9]{5,20}$/, 'Driving License Number must be 5 to 20 alphanumeric digits');
        maxLengthMap[field.value] = 20;
        break;
      case 'epic_number':
        schemaFields[field.value] = yup.string().required('Voter ID Epic number is required').matches(/^[A-Za-z0-9]{5,20}$/, 'Invalid Voter ID Epic number');
        maxLengthMap[field.value] = 20;
        break;
      case 'aadhaar_number':
        schemaFields[field.value] = yup.string().required('Aadhar Number is required').matches(/^[0-9]{12}$/, 'Aadhar Number must be exactly 12 numeric digits')
        maxLengthMap[field.value] = 12;
        break;
      case 'aadhaar':
        schemaFields[field.value] = yup.string().required('Aadhar Number is required').matches(/^[0-9]{12}$/, 'Aadhar Number must be exactly 12 numeric digits')
        maxLengthMap[field.value] = 12;
        break;
      case 'gst_number':
        schemaFields[field.value] = yup.string().required('GST Number is required').matches(/^[A-Za-z0-9]{15}$/, 'GST Number must be 15 alphanumeric digits');
        maxLengthMap[field.value] = 15;
        break;
      case 'gstNumber':
        schemaFields[field.value] = yup.string().required('GST Number is required').matches(/^[A-Za-z0-9]{15}$/, 'GST Number must be 15 alphanumeric digits');
        maxLengthMap[field.value] = 15;
        break;
      case 'mobile_number':
        schemaFields[field.value] = yup.string().required('Mobile Number is required').matches(/^[0-9]{10}$/, 'Mobile Number must be exactly 10 numeric digits')
        maxLengthMap[field.value] = 10;
        break;
      case 'iec_number':
        schemaFields[field.value] = yup.string().required('ICE Number is required').matches(/^[A-Za-z0-9]{10}$/, 'ICE Number must be 10 alphanumeric digits');
        maxLengthMap[field.value] = 10;
        break;
      case 'vehicleNumber':
        schemaFields[field.value] = yup.string().required('vehicle Number is required').matches(/^[A-Za-z0-9]{10}$/, 'vehicle Number must be 10 alphanumeric digits');
        maxLengthMap[field.value] = 10;
        break;
      case 'vehicle_number':
        schemaFields[field.value] = yup.string().required('vehicle Number is required').matches(/^[A-Za-z0-9]{10}$/, 'vehicle Number must be 10 alphanumeric digits');
        maxLengthMap[field.value] = 10;
        break;
      default:
        schemaFields[field.value] = yup.string().required(`Please enter ${field.label}`);
    }
  });

  return { schema: yup.object().shape(schemaFields), maxLengthMap };
};
