import { useState } from 'react';
import { useTheme } from '@emotion/react';
import { Box, Stack, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Tabs, Tab } from '@mui/material';
import { Loader } from '../loader';
import PdfViewer from '../../utils/pdfViewer/PdfViewer';

const ResponseTable = ({ data }) => {
  if (!data || typeof data !== 'object') return null;

  const displayData = data.text || data;

  const shouldDisplayValue = (value) => {
    return value !== undefined && 
           value !== null &&
           value !== "" && 
           !(Array.isArray(value) && value.length === 0) &&
           !(typeof value === 'object' && value !== null && Object.keys(value).length === 0);
  };

  const renderValue = (value) => {
    if (value === "" || !shouldDisplayValue(value)) return 'N/A';
    if (Array.isArray(value)) return value.join(', ');
    if (typeof value === 'object' && value !== null) return 'N/A';
    return value?.toString() || 'N/A';
  };

  const renderSectionHeader = (title) => {
    return (
      <TableRow>
        <TableCell 
          colSpan={2}
          sx={{
            fontWeight: 'bold',
            fontSize: '14px',
            backgroundColor: '#f1f1f1',
            textAlign: 'center',
            border: '1px solid #dddddd',
            py: 1
          }}
        >
          {title}
        </TableCell>
      </TableRow>
    );
  };

  const renderObjectRows = (obj, prefix = '') => {
    if (!obj || typeof obj !== 'object') {
      return null;
    }

    return Object.entries(obj)
      .filter(([key, value]) => {
        if (key === 'detectedFiles') return false;
        return value !== undefined;
      })
      .map(([key, value]) => {
        if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
          return (
            <>
              {renderSectionHeader(key)}
              {renderObjectRows(value, `${prefix}${key}.`)}
            </>
          );
        }

        return (
          <TableRow key={`${prefix}${key}`}>
            <TableCell sx={{ 
              pl: 6,
              fontSize: '14px',
              border: '1px solid #dddddd',
              width: '40%'
            }}>
              {key}
            </TableCell>
            <TableCell sx={{ 
              fontSize: '14px',
              border: '1px solid #dddddd',
              width: '60%'
            }}>
              {renderValue(value)}
            </TableCell>
          </TableRow>
        );
      });
  };

  return (
    <TableContainer component={Paper} sx={{ 
      mb: 3, 
      maxHeight: '60vh', 
      overflow: 'auto',
      border: '1px solid #dddddd',
      boxShadow: 'none',
      '& .MuiTableCell-root': {
        padding: '8px 16px'
      }
    }}>
      <Table size="small" stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell sx={{ 
              fontWeight: 'bold', 
              fontSize: '14px',
              border: '1px solid #dddddd',
              backgroundColor: '#f1f1f1',
              width: '40%'
            }}>
              Field
            </TableCell>
            <TableCell sx={{ 
              fontWeight: 'bold', 
              fontSize: '14px',
              border: '1px solid #dddddd',
              backgroundColor: '#f1f1f1',
              width: '60%'
            }}>
              Value
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {renderObjectRows(displayData)}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const TabPanel = ({ children, value, index }) => {
  return (
    <div role="tabpanel" hidden={value !== index}>
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

export function ApiResponse({ loader, response, errorObject }) {
  const theme = useTheme();
  const [pageNumber, setPageNumber] = useState(1);
  const [tabValue, setTabValue] = useState(0);
  let content = null;
  const isPdf = response?.image?.masked && (response.image.masked.endsWith('.pdf') || response.image.masked.endsWith('.PDF')) && response.image.masked.startsWith('http');

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  if (isPdf) {
    content = (
      <PdfViewer
        open
        onClose={() => {}}
        isPdf={isPdf}
        previewImage={response?.image?.masked}
        setPageNumber={setPageNumber}
        pageNumber={pageNumber}
        useDialog={false}
      />
    );
  } else if (response?.image?.masked) {
    content = (
      <Box
        sx={{ width: '100%', height: '100%' }}
        component="img"
        src={response?.image?.masked}
      />
    );
  } else if (response?.image?.error) {
    content = (
      <Box sx={{ px: 2, pt: 3 }}>
        <Typography>
          <pre
            style={{
              whiteSpace: 'pre-wrap',
              background: '#f0f0f0',
              padding: '10px',
              minHeight: '40rem',
            }}
          >
            {JSON.stringify(response?.image?.error, null, 2)}
          </pre>
        </Typography>
      </Box>
    );
  } else if (response?.text) {
    const updatedResponse = {
      ...response.text,
      detectedFiles: response?.text?.detectedFiles?.map(file =>
        file.replace(/\n/g, "")
      )
    };
    content = (
      <Box sx={{ px: 2, pt: 3 }}>
        <Tabs value={tabValue} onChange={handleTabChange}>
          <Tab label="Data Preview" sx={{fontSize: '1.2rem',fontWeight: 'bold'}} />
          <Tab label="JSON" sx={{fontSize: '1.2rem',fontWeight: 'bold'}} />
        </Tabs>

        <TabPanel value={tabValue} index={0}>
          <ResponseTable data={response} />
        </TabPanel>

        <TabPanel value={tabValue} index={1}>
          <Typography>
            <pre
              style={{
                whiteSpace: 'pre-wrap',
                background: '#f0f0f0',
                padding: '10px',
                minHeight: '40rem',
                overflowX: "auto"
              }}
            >
              {JSON.stringify(updatedResponse, null, 2)}
            </pre>
          </Typography>
        </TabPanel>
      </Box>
    );
  }

  const status = loader ? null : response?.status;

  const statusColor = status === 200 ? 'green' : 'red';

  return (
    <Stack>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography
          sx={{
            mt: 2,
            mb: 2,
            color: theme.palette.textColor.primary,
          }}
          variant="h5"
        >
          Status:
        </Typography>
        <Typography
          sx={{
            color: statusColor,
            px: 1,
          }}
          variant="h5"
        >
          {loader ? <Loader /> : status}
        </Typography>
      </Box>
      <Box
        sx={{
          background: theme.palette.background.main,
          width: '100%',
          height: '100%',
          minHeight: '40rem',
        }}
      >
        {content}
      </Box>
    </Stack>
  );
}

export default ApiResponse;